//#region  imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

//#endregion imports
//#region  internal imports
import { HttpProvider } from 'app/core/common/http-provider.service';
import { Constant } from 'app/core/common/constant';
import { Login } from 'app/core/model/authentication/login.modal';
import { SignUp } from 'app/core/model/authentication/signup.modal';
import { LoggedUser } from 'app/core/model/users/logged-user.modal';
//#endregion internal imports

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends HttpProvider {

  //#region variable declaration
  private readonly authenticateEndPoint = '/login/';
  private readonly logoutEndPoint = '/logout/';
  private readonly forgotPasswordEndpoint = '/forgot_password/';
  private readonly resetPasswordEndpoint = '/reset_password/';
  private readonly organizationSetupEndpoint = '/organization_sign_up/';
  private readonly validateEmailEndpoint = '/validate_email/';
  private readonly validateMobileEndpoint = '/validate_mobile/';
  private readonly paymentStatusEndpoint = '/payment_link_success_fail/';
  setUpData: SignUp;
  //#endregion variable declaration
  private readonly countryCodeEndpoint = '/country/';
  private readonly paymentLinkEndpoint = '/razor_pay_payment_link_creation/';
  private readonly OrgValidationEndpoint = '/org_validation/';
  private readonly changeUserDataEndpoint = '/organization_data_change/';
  private readonly orgEmailVerifierEndpoint = '/org_email_verification/';
  private readonly userDataViaVCIPIDEndpoint = '/kyc/get_user_data_via_vcipid/';
  private readonly accountDeletionEndpoint = '/account_deletion/';
  private readonly userMobileVerifierEndpoint = '/mobile_verification_kyc/';
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  //#region Dropdown
  getCountryCode(activated_only=1) {
    const queryParams = {
      activated_only:activated_only
    };
    return this.get(this.countryCodeEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }
  getIndustry() {
    // throw new Error('Method not implemented.');
  }
  getOrganizationSize() {
    // throw new Error('Method not implemented.');
  }

  //#endregion

  public getToken(): string {
    return localStorage.getItem(Constant.LocalStorageKey.Token);
  }

  public signUp(userInfo: Login) {
    this.setUpData = new SignUp();
    this.setUpData.email = userInfo.username;
    this.setUpData.password = userInfo.password;
  }

  public login(userInfo: Login) {
    this.setUpData = new SignUp();
    this.setUpData.email = userInfo.username;
    return this.post(this.authenticateEndPoint, userInfo);
  }

  logout() {
    return this.get(this.logoutEndPoint);
  }

  sendForgotPasswordMail(mail) {
    return this.post(this.forgotPasswordEndpoint, mail);
  }

  resetPassword(value: any) {
    return this.put(this.resetPasswordEndpoint, value);
  }

  checkResetPassword(value: any) {
    return this.post(this.resetPasswordEndpoint, value);
  }

  saveOrganizationSetup(value: any) {
    return this.post(this.organizationSetupEndpoint, value);
  }

  validateEmail(value: any) {
    return this.post(this.validateEmailEndpoint, value);
  }
  validateMobile(value: any) {
    return this.post(this.validateMobileEndpoint, value);
  }
  //#region  get/set
  getSetUpData(): SignUp {
    return this.setUpData;
  }

  getUserRoute(user: LoggedUser, currentRole) {
    switch (currentRole) {
      case Constant.RoleName.Admin:
        if (user.primary_account && !user.status) {
          return Constant.SetUpRoute;
        } else {
          return Constant.DashboardRoute.Admin;
        }
      case Constant.RoleName.Finance:
        return Constant.DashboardRoute.Finance;
      case Constant.RoleName.Manager:
        return Constant.DashboardRoute.Manager;
      case Constant.RoleName.User:
        return Constant.DashboardRoute.User;
      default:
        // Constant.SetUpRoute
        return null;
    }
  }

  validatePaymentstatus(element: any) {
    return this.post(this.paymentStatusEndpoint, element);
  }

  getProformById(pi_id: number) {
    return this.get(`/proposal/${pi_id}`);
  }

  getPaymentLink(elemnt: any) {
    return this.post(this.paymentLinkEndpoint, elemnt);
  }

  getOrganizationVerification(mode, overwrite) {
    const queryParams = {
      verification: mode,
      overwrite: overwrite,
    };
    return this.get(this.OrgValidationEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  checkOrganizationVerification(elemnt: any) {
    return this.post(this.OrgValidationEndpoint, elemnt);
  }

  changeuserData(data) {
    return this.post(this.changeUserDataEndpoint, data);
  }

  verifyOrganizationEmail(data) {
    return this.post(this.orgEmailVerifierEndpoint, data);
  }

  deletionAccount(data) {
    return this.post(this.accountDeletionEndpoint, data);
  }

  getUserDataViaVCIPID(data,country) {
    const queryParams = {
      vcipid: data,
      country:country
    };
    return this.get(this.userDataViaVCIPIDEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }


  getUserverificationOTP(data,country) {
    const queryParams = {
      vcipid: data,
      country:country
    };
    return this.get(this.userMobileVerifierEndpoint, true, {
      params: this.toHttpParams(queryParams),
    });
  }

  validateUserOtp(data) {
    return this.post(this.userMobileVerifierEndpoint, data);
    }
  //#endregion
}
