<div class="row h-100">
  <div class="col-4 offset-4 m-auto text-center">
    <h4 class="pin-title" *ngIf="mode == 'set'">Set your PIN</h4>
    <h4 class="pin-title" *ngIf="mode == 're-confirm'">Confirm your PIN</h4>

    <h6 class="pin-subtitle" *ngIf="mode == 'set'">
      Set your Bizpay security code for a safe app experience
    </h6>
    <h6 class="pin-subtitle" *ngIf="mode == 're-confirm'">
      Re-enter your Bizpay security code    </h6>

    <p class="pin-message" *ngIf="mode == 'set'">
      This code will be required while using any sensitive app features such as
      accessing wallet screen, loading money to wallets etc.
    </p>
    <p class="pin-message" *ngIf="mode == 're-confirm'">
      To confirm your PIN, please re-enter the same PIN . This ensures accuracy
      and helps prevent any errors during the setup process. Once confirmed,
      your PIN will be securely stored for future access to your account.
    </p>

    <ng-otp-input
      (onInputChange)="onOtpChange($event)"
      *ngIf="mode == 'set'"
      #otpInput
      [config]="{
        length: 4,
        allowNumbersOnly: true,
        isPasswordInput: false,
        inputClass: 'otp-num-custom'
      }"
    ></ng-otp-input>
    <ng-otp-input
      (onInputChange)="onOtpChange($event)"
      #otpInput1
      *ngIf="mode == 're-confirm'"
      [config]="{
        length: 4,
        allowNumbersOnly: true,
        isPasswordInput: true,
        inputClass: 'otp-num-custom'
      }"
    ></ng-otp-input>
    <small class="text-danger my-2" *ngIf="mismatch">PIN not matched.</small>
    <div class="button-gap" *ngIf="mode == 'set'">
      <button
        type="button"
        label="Cancel"
        (click)="cancelPin()"
        class="btn btn-outline-primary me-3 px-3"
      >Cancel</button>

      <p-button
        label="Set PIN"
        styleClass="px-4"
        (click)="setPin()"
        [disabled]="!setButtonEnabled"
      ></p-button>
    </div>
    <div class="button-gap" *ngIf="mode == 're-confirm'">
      <p-button
        label="Confim Pin"
        styleClass="px-5"
        (click)="confirmPin()"
        [disabled]="!cnfButtonEnabled"
      ></p-button
      >
    </div>
  </div>
</div>
